import { withZod } from '@remix-validated-form/with-zod';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'remix-validated-form';
import { z } from 'zod';
import { zfd } from 'zod-form-data';

import { HiddenArrayInput, HiddenInput } from '~/components';
import {
  MultiStepFormWrapperProvider,
  useKnowledgeBase,
  useMultiStepFormWrapperState,
  useUser,
} from '~/providers';
import { fadeInMoveUp } from '~/utils';

import { Field } from '../Form';
import { FormStepTitle } from '../FormStepTitle';
import { ToolFunctionalitySection } from './FunctionalitySelection';
import { ScopeSection, SourceSelectionSection } from './ScopeIndexSelection';
import { ToolTemplateSection } from './TemplateSelection';
import { ToolTypeSection } from './TypeSelection';

import type { FunctionalityId } from './FunctionalitySelection';
import type { Instance, Source, ToolType, WidgetTemplate } from '~/models';

export const toolFormSchema = z
  .object({
    title: z.string().min(1, { message: 'Title is required' }),
    toolType: z.string().min(1, { message: 'Tool type is required' }),
    template: zfd.text(z.string().optional()),
    scope: zfd.text(z.enum(['internal', 'external'])),
    sourceIds: zfd.repeatable(
      z
        .array(zfd.text())
        .min(1, { message: 'You must select at least one data source' }),
    ),
    accountId: zfd.numeric(),
    knowledgeBaseId: zfd.numeric(),
    locale: zfd.text(),
    functionality: zfd.repeatable(z.array(zfd.text()).max(2)),
  })
  .refine((data) => data.toolType !== 'unknown', {
    message: 'User Interface type is required',
    path: ['toolType'],
  })
  .refine(
    (data) =>
      data.toolType === 'widget'
        ? data.template !== undefined && data.template !== ''
        : true,
    {
      message: 'You must select a template for the widget tool type',
      path: ['template'],
    },
  );

export const toolFormValidator = withZod(toolFormSchema);

const emptySchema = z.object({});
const emptyFormValidator = withZod(emptySchema);

export type ToolNewWizardProps = {
  isWidgetsLimitReached: boolean;
  returnTo: string;
  sources: Source[];
  toolsCount: Record<string, number>;
};

export const ToolNewWizard = ({ returnTo, ...props }: ToolNewWizardProps) => {
  const { account } = useUser();
  const { currentKnowledgeBase } = useKnowledgeBase();

  return (
    <MultiStepFormWrapperProvider
      returnTo={returnTo}
      steps={5}
      current={1}
      submitBtnLabel="Complete"
      formId="new-tool-form"
      className="overflow-hidden"
      formClassName="p-1 !w-full flex justify-center overflow-hidden"
      validator={emptyFormValidator}
      defaultValues={{
        accountId: account.id,
        knowledgeBaseId: currentKnowledgeBase?.id,
        locale: currentKnowledgeBase?.language,
      }}
      stepCounterClassName="!w-3/5 !p-2"
    >
      <ToolNew {...props} />
    </MultiStepFormWrapperProvider>
  );
};

export type ToolNewProps = {
  isWidgetsLimitReached: boolean;
  sources: Source[];
  toolsCount: Record<string, number>;
};

export const ToolNew = ({
  sources,
  isWidgetsLimitReached,
  toolsCount,
}: ToolNewProps) => {
  const { account } = useUser();
  const { currentKnowledgeBase } = useKnowledgeBase();

  const { fieldErrors } = useFormContext('new-tool-form');

  const [title, setTitle] = useState('');

  const [toolType, setToolType] = useState<ToolType>('unknown');
  const [template, setTemplate] = useState<WidgetTemplate | ''>('');
  const [functionality, setFunctionality] = useState<FunctionalityId[]>([]);

  const [scope, setScope] = useState<Instance['scope']>('external');
  const [sourceIds, setSourceIds] = useState<number[]>([]);

  const { currentStep, setDisableNextStep, setSkippedSteps } =
    useMultiStepFormWrapperState();

  const defaultTitle = useMemo(() => {
    if (toolType === 'widget') {
      return `${template} - ${toolsCount[template] ?? 0}`;
    }

    return `${toolType} - ${toolsCount[toolType] ?? 0}`;
  }, [template, toolType, toolsCount]);

  const handleToolTypeChange = (selectedToolType: ToolType) => {
    setToolType(selectedToolType);

    if (selectedToolType === 'desktop' || selectedToolType === 'extension') {
      setScope('internal');
    }
  };

  const handleToolFunctionalityChange = (
    selectedFunctionality: FunctionalityId,
  ) => {
    setFunctionality((prev) =>
      prev.includes(selectedFunctionality)
        ? prev.filter((id) => id !== selectedFunctionality)
        : [...prev, selectedFunctionality],
    );
  };

  const handleScopeChange = (value: string) => {
    setScope(value as Instance['scope']);

    if (value === 'external') {
      const filteredSourceIds = sourceIds.filter((sourceId) => {
        const source = sources.find((source) => source.id === sourceId);
        return !source?.internal;
      });

      setSourceIds(filteredSourceIds);
    }
  };

  const handleSelectSource = (sourceId: Source['id']) => {
    setSourceIds((ids) =>
      ids.includes(sourceId)
        ? ids.filter((id) => id !== sourceId)
        : [...ids, sourceId],
    );
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setFunctionality([]);
        setDisableNextStep(toolType === 'unknown');
        if (toolType === 'widget') {
          setSkippedSteps([]);
        }

        if (toolType === 'api') {
          setSkippedSteps([2]);
        }
        break;
      case 2:
        if (toolType === 'widget') {
          setDisableNextStep(template === '');
        }
        break;
      case 3:
        setDisableNextStep(functionality?.length === 0);
        break;
      case 5:
        !title && setTitle(defaultTitle);
      default:
        setDisableNextStep(false);
        break;
    }
  }, [
    currentStep,
    setDisableNextStep,
    toolType,
    template,
    setSkippedSteps,
    defaultTitle,
    title,
    setTitle,
    functionality?.length,
  ]);

  const sharedMotionDivStyle =
    'h-full flex flex-col gap-12 overflow-y-auto w-3/5 items-left px-1';

  return (
    <>
      {currentStep === 1 && (
        <motion.div {...fadeInMoveUp} className={clsx(sharedMotionDivStyle)}>
          <ToolTypeSection
            error={fieldErrors['toolType']}
            handleToolTypeChange={handleToolTypeChange}
            toolType={toolType}
            isWidgetsLimitReached={isWidgetsLimitReached}
          />
        </motion.div>
      )}

      {currentStep === 2 && (
        <motion.div {...fadeInMoveUp} className={clsx(sharedMotionDivStyle)}>
          {toolType === 'widget' && (
            <ToolTemplateSection
              error={fieldErrors['template']}
              handleToolTemplateChange={setTemplate}
              template={template}
            />
          )}
        </motion.div>
      )}

      {currentStep === 3 && (
        <motion.div {...fadeInMoveUp} className={clsx(sharedMotionDivStyle)}>
          <ToolFunctionalitySection
            error={fieldErrors['functionality']}
            handleToolFunctionalityChange={handleToolFunctionalityChange}
            selectedFunctionality={functionality}
            toolType={toolType}
          />
        </motion.div>
      )}

      {currentStep === 4 && (
        <motion.div {...fadeInMoveUp} className={clsx(sharedMotionDivStyle)}>
          <FormStepTitle
            title="User Interface name"
            description="Give your User Interface a name"
          />

          <Field
            id="tool-title"
            name="title"
            label=""
            placeholder={defaultTitle}
            error={fieldErrors['title']}
            onChange={(e) => setTitle(e.target.value)}
          />
        </motion.div>
      )}

      {currentStep === 5 && (
        <motion.div
          {...fadeInMoveUp}
          className={clsx('pb-[100px] no-scrollbar', sharedMotionDivStyle)}
        >
          <FormStepTitle
            title="Configure data sources for this User Interface"
            description="Choose between your different indexes to control the data this User Interface has access to."
          />

          <ScopeSection
            scope={scope}
            handleScopeChange={handleScopeChange}
            toolType={toolType}
          />

          <SourceSelectionSection
            error={fieldErrors['sourceIds']}
            selectedSources={sourceIds}
            sources={sources}
            handleSelectSource={handleSelectSource}
            scope={scope}
          />

          <HiddenInput name="title" value={title} />
          <HiddenInput name="toolType" value={toolType} />
          <HiddenInput name="template" value={template} />
          <HiddenArrayInput name="functionality" value={functionality} />
          <HiddenInput name="scope" value={scope} />
          <HiddenArrayInput
            name="sourceIds"
            value={sourceIds.map((id) => id.toString())}
          />
          <HiddenInput name="accountId" value={account.id.toString()} />
          <HiddenInput
            name="knowledgeBaseId"
            value={currentKnowledgeBase?.id.toString()}
          />
          <HiddenInput name="locale" value={currentKnowledgeBase?.language} />
        </motion.div>
      )}
    </>
  );
};
