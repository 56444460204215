import type { Role } from '~/models';

export const rolesDict: Record<Role, string> = {
  raffle_admin: 'Raffle Admin',
  data_science_admin: 'DS Admin',
  demo_admin: 'Demo Admin',
  account_admin: 'Account Admin',
  knowledgebase_manager: 'KB Manager',
  insighter: 'Insighter',
  user: 'User',
};

// raffle_admin and demo_admin are mutually exclusive
export const rolesAbstraction: Record<Role, Role[]> = {
  raffle_admin: [
    'raffle_admin',
    'account_admin',
    'knowledgebase_manager',
    'insighter',
    'user',
  ],
  data_science_admin: ['raffle_admin', 'data_science_admin'],
  demo_admin: [
    'demo_admin',
    'account_admin',
    'knowledgebase_manager',
    'insighter',
    'user',
  ],
  account_admin: [
    'account_admin',
    'knowledgebase_manager',
    'insighter',
    'user',
  ],
  knowledgebase_manager: ['knowledgebase_manager', 'insighter', 'user'],
  insighter: ['insighter', 'user'],
  user: ['user'],
};

export const parseRole = (userRoles: Role[]) => {
  const roleKeys = Object.keys(rolesAbstraction);

  for (let i = 0; i < roleKeys.length; i++) {
    const key = roleKeys[i] as Role;

    if (userRoles.includes(key)) {
      return key;
    }
  }

  return 'user';
};

export const raffleAdminRoles: Role[] = ['raffle_admin', 'demo_admin'];

export const adminRoles: Role[] = [
  'account_admin',
  'raffle_admin',
  'demo_admin',
];

export const roleOptions = Object.entries(rolesDict).map(([value, label]) => ({
  value: value as Role,
  label,
}));

export function filterRoleOptions(userRoles: Role[]) {
  return roleOptions.filter(
    (s) =>
      s.value !== 'raffle_admin' &&
      s.value !== 'demo_admin' &&
      s.value !== 'data_science_admin' &&
      s.value !== 'user',
  );
}

export const isRaffleAdmin = (userRoles: Role[]) =>
  userRoles.includes('raffle_admin') || userRoles.includes('demo_admin');

export const isAccountAdmin = (userRoles: Role[]) =>
  userRoles.includes('account_admin');

type HasRolePermissionProps = {
  roles: Role[];
  userRoles: Role[];
};
export function hasRolePermission({
  roles,
  userRoles,
}: HasRolePermissionProps) {
  return roles.length
    ? isRaffleAdmin(userRoles) || userRoles.some((ur) => roles.includes(ur))
    : true;
}

type RouteType =
  | 'insights'
  | 'backstage'
  | 'sources'
  | 'tools'
  | 'settings'
  | 'api'
  | 'home'
  | 'rerank';

export const routePermissions: Record<RouteType, Role[]> = {
  insights: ['knowledgebase_manager', 'insighter'],
  backstage: ['raffle_admin', 'demo_admin'],
  sources: ['knowledgebase_manager'],
  tools: ['account_admin'],
  rerank: ['knowledgebase_manager'],
  settings: ['account_admin'],
  api: ['account_admin'],
  home: ['insighter'],
};
