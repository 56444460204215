import {
  CheckCircleIcon,
  CircleStackIcon,
  CursorArrowRippleIcon,
  DocumentTextIcon,
  NoSymbolIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  Form,
  Link,
  useLocation,
  useNavigation,
  useOutletContext,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';
import { AnimatePresence, motion } from 'framer-motion';
import { PenBox } from 'lucide-react';
import { useMemo } from 'react';
import { confirmAlert } from 'react-confirm-alert';

import {
  ButtonTooltip,
  Card,
  Chip,
  CONFIRM_MODAL_OVERLAY_CLASSNAME,
  DeleteConfirmModal,
  Input,
  LoadingSpinner,
  Pagination,
  PressToCopyText,
  TimestampText,
} from '~/components';
import { Button } from '~/components/ui/button';
import { PAGINATION_FETCH_LIMIT } from '~/routes/_app.backstage.sources_.$sourceId.answers/view';
import { fileTypesImg, inferExcludeOption } from '~/utils';

import InfoCard from '../InfoCard';
import { ContextMenuItem } from '../ui/context-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { TableBottombar } from './TableBottombar';
import TableCompact from './TableCompact';

import type { ColumnDef } from '@tanstack/react-table';
import type { TableProps } from '~/components';
import type { AnswerPartial, AnswersResponse, Source } from '~/models';

const columns: Array<ColumnDef<AnswerPartial>> = [
  {
    id: 'id',
    accessorKey: 'id',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600 [&:has([role=checkbox])]:pr-0"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        ID
      </div>
    ),
    cell: ({ getValue }) => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Chip className="!px-2">
              <PressToCopyText>{getValue<number>()}</PressToCopyText>
            </Chip>
          </TooltipTrigger>
          <TooltipContent className="text-[13px]" side="right">
            Click to copy
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    sortingFn: (a, b) => a.original.title.localeCompare(b.original.title),
  },
  {
    id: 'document',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Document
      </div>
    ),
    cell: ({ row: { original } }) => (
      <DocumentCell fileName={original.external_source_id} />
    ),
    sortingFn: (a, b) => a.original.title.localeCompare(b.original.title),
  },
  {
    id: 'content',
    accessorKey: 'content',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Content
      </div>
    ),
    cell: ({ getValue }) => (
      <div
        dangerouslySetInnerHTML={{ __html: getValue<string>() }}
        className="h-5 overflow-hidden text-[13px] text-neutral-600"
      />
    ),
    sortingFn: (a, b) => a.original.title.localeCompare(b.original.title),
  },
  {
    id: 'source_url',
    accessorKey: 'source_url',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        URL
      </div>
    ),
    cell: ({ getValue }) => (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <PressToCopyText toastMessage="URL">
              {getValue<string>()}
            </PressToCopyText>
          </TooltipTrigger>
          <TooltipContent className="text-[13px]" side="right">
            Click to copy
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    sortingFn: (a, b) => a.original.title.localeCompare(b.original.title),
  },
  {
    id: 'title',
    accessorKey: 'title',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Title
      </div>
    ),
    cell: ({ getValue }) => (
      <p className="text-[13px] font-medium">{getValue<string>()}</p>
    ),
    sortingFn: (a, b) => a.original.title.localeCompare(b.original.title),
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Added
      </div>
    ),
    cell: ({ getValue }) => (
      <TimestampText isBackstage>{getValue<string>()}</TimestampText>
    ),
    sortingFn: (a, b) => {
      const aDate = new Date(a.original.created_at);
      const bDate = new Date(b.original.created_at);
      return aDate.getTime() - bDate.getTime();
    },
  },
  {
    id: 'updated_at',
    accessorKey: 'updated_at',
    header: ({ column }) => (
      <div
        className="h-fit w-fit -translate-x-2 cursor-pointer rounded-md border-transparent px-2 py-1 hover:bg-neutral-250 hover:text-neutral-600"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Updated
      </div>
    ),
    cell: ({ getValue }) => (
      <TimestampText isBackstage>{getValue<string>()}</TimestampText>
    ),
    sortingFn: (a, b) => {
      const aDate = new Date(a.original.created_at);
      const bDate = new Date(b.original.created_at);
      return aDate.getTime() - bDate.getTime();
    },
  },
  {
    id: 'options',
    header: () => <div>Options</div>,
    cell: ({ row: { original } }) => <Options original={original} />,
  },
];

const Options = ({ original }: { original: AnswerPartial }) => {
  const { source } = useOutletContext<AnswersOutletData>();
  const { pathname, search } = useLocation();
  const submit = useSubmit();

  const isBackstage = useMemo(() => pathname.includes('backstage'), [pathname]);

  const isExcluded =
    inferExcludeOption(source as Source, original.external_source_id) !==
    'include';

  const id =
    source.type === 'instant_answers'
      ? original.external_source_id
      : original.id;

  if (source.type === 'instant_answers') {
    return (
      <div className="flex items-center gap-3">
        <Link to={`${id}${search}`}>
          <Button variant="outline" size="sm">
            Edit
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-start gap-3">
      <Link to={`${id}${search}`}>
        <Button variant="outline" size="sm">
          Edit
        </Button>
      </Link>

      {source.type === 'web_scrape' && isBackstage && (
        <Link to={`${id}/exclude${search}`}>
          <ButtonTooltip
            Icon={isExcluded ? CheckCircleIcon : NoSymbolIcon}
            variant="secondary"
          >
            Exclude
          </ButtonTooltip>
        </Link>
      )}

      {source.type === 'manual_upload' && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            confirmAlert({
              overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
              customUI: ({ onClose }) => (
                <DeleteConfirmModal
                  resource={`answer "${original.title}"`}
                  onClose={onClose}
                  onAccept={() =>
                    submit(null, {
                      method: 'delete',
                      action: `${pathname}/${id}`,
                    })
                  }
                />
              ),
            });
          }}
        >
          <ButtonTooltip type="submit" Icon={TrashIcon} variant="secondary">
            Delete
          </ButtonTooltip>
        </Form>
      )}
    </div>
  );
};

export type AnswersTableProps = Omit<TableProps<AnswerPartial>, 'columns'> & {
  handleDrop?: (acceptedFiles: File[]) => void;
  isBackstage?: boolean;
  pagination: AnswersResponse['pagination'];
};

export type AnswersOutletData = {
  source: {
    configuration: Source['configuration'];
    id: Source['id'];
    knowledge_base_id: Source['knowledge_base_id'];
    title?: Source['title'];
    type: Source['type'];
  };
};

export const AnswersTable = ({
  data,
  isBackstage = false,
  pagination,
  handleDrop,
}: AnswersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigation = useNavigation();
  const { pathname } = useLocation();

  const { source } = useOutletContext<AnswersOutletData>();

  const filter = searchParams.get('filter') || '';

  const handlePageChange = (page: number) => {
    const offset = (page - 1) * PAGINATION_FETCH_LIMIT;

    if (offset < 0 || offset >= pagination.count) {
      return;
    }

    setSearchParams({
      offset: offset.toString(),
      ...(filter.length > 0 && {
        filter,
        knowledge_base_id: source.knowledge_base_id.toString(),
      }),
    });
  };

  const submit = useSubmit();

  const contextMenuContent = (
    row: AnswerPartial,
    isAdmin?: boolean,
    features?: any,
    search?: string,
  ) => {
    const id =
      source.type === 'instant_answers' ? row.external_source_id : row.id;
    const isExcluded =
      inferExcludeOption(source as Source, row.external_source_id) !==
      'include';

    const title = row.title;

    if (source.type === 'instant_answers') {
      return (
        <Link to={`${id}${search}`}>
          <ContextMenuItem className="flex flex-row justify-between gap-6">
            Edit
            <PenBox className="h-3 w-3 text-neutral-450" />
          </ContextMenuItem>
        </Link>
      );
    }

    return (
      <>
        <Link to={`${id}${search}`}>
          <ContextMenuItem className="flex flex-row justify-between gap-6">
            Edit
            <PenBox className="h-3 w-3 text-neutral-450" />
          </ContextMenuItem>
        </Link>
        {source.type === 'web_scrape' && isBackstage && (
          <Link to={`${id}/exclude${search}`}>
            <ContextMenuItem className="flex flex-row justify-between gap-6">
              {isExcluded ? 'Include' : 'Exclude'}
              {isExcluded ? (
                <CheckCircleIcon className="h-3 w-3 text-neutral-450" />
              ) : (
                <NoSymbolIcon className="h-3 w-3 text-accent-green-500" />
              )}
            </ContextMenuItem>
          </Link>
        )}
        {source.type === 'manual_upload' && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              confirmAlert({
                overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
                customUI: ({ onClose }) => (
                  <DeleteConfirmModal
                    resource={`answer "${title}"`}
                    onClose={onClose}
                    onAccept={() =>
                      submit(null, {
                        method: 'delete',
                        action: `${pathname}/${id}`,
                      })
                    }
                  />
                ),
              });
            }}
          >
            <button type="submit" className="w-full">
              <ContextMenuItem className="flex flex-row justify-between gap-6">
                Delete
                <TrashIcon className="h-3 w-3 text-accent-red-500" />
              </ContextMenuItem>
            </button>
          </Form>
        )}
      </>
    );
  };

  const hiddenColumns = {
    content: false,
    document: false,
    ...(!isBackstage && {
      id: false,
    }),
    ...(source.type === 'instant_answers' && {
      title: false,
      content: true,
    }),
    ...(source.type === 'manual_upload' && {
      title: false,
      document: true,
      source_url: false,
    }),
  };

  return (
    <>
      <AnimatePresence mode="popLayout">
        <motion.div layout className="flex flex-1 flex-col overflow-hidden">
          <InfoCard
            id="answerInfo"
            Icon={CircleStackIcon}
            variant="Default"
            title="Answers: These are the answers in your index"
          >
            <div className="flex flex-col gap-2">
              <p className="text-[13px]">
                This list shows all answers that populate your selected {''}
                <span className="font-semibold">index</span>.
              </p>
              <div className="flex flex-row items-center gap-1.5">
                <CursorArrowRippleIcon className="h-4 w-4" />
                <p className="text-[13px]">
                  <span className="font-semibold">Right click </span> to view
                  the answer or add more questions leading to the answer.
                </p>
              </div>
            </div>
          </InfoCard>
          <div className="mb-3">
            {/* TODO: Clearing input field trigger loader filter */}
            <Form method="get" replace className="w-1/3" action={pathname}>
              <Input
                type="search"
                id="search-source-db"
                placeholder={`Filter index database by${
                  isBackstage ? ' id,' : ''
                } source URL or title`}
                name="filter"
                defaultValue={filter}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setSearchParams({});
                  }
                }}
              />
              <Input
                name="knowledge_base_id"
                defaultValue={source.knowledge_base_id}
                id="search-source-db-kbId"
                hidden
              />
            </Form>
          </div>

          <div className="flex flex-1 flex-col gap-6 overflow-hidden">
            <Card className="z-0 overflow-auto rounded-b-none rounded-t-md !border-neutral-250 !p-0 shadow-none">
              <TableCompact
                data={data}
                columns={columns}
                hiddenColumns={hiddenColumns}
                contextMenu={true}
                contextMenuContent={contextMenuContent}
                handleDrop={handleDrop}
                allowDrop={source.type === 'manual_upload'}
              />
            </Card>
            {data && data?.length > 0 && (
              <TableBottombar className="-mt-6">
                <Pagination
                  page={pagination.offset / PAGINATION_FETCH_LIMIT + 1}
                  pages={
                    Math.floor(pagination.count / PAGINATION_FETCH_LIMIT) + 1
                  }
                  entries={pagination.count}
                  onPageChange={(page) => handlePageChange(page)}
                  disabledPrev={pagination.offset < PAGINATION_FETCH_LIMIT}
                  disabledNext={
                    pagination.offset + PAGINATION_FETCH_LIMIT >=
                    pagination.count
                  }
                  loading={navigation.state === 'loading'}
                />

                {navigation.state === 'loading' && (
                  <LoadingSpinner className="h-6 w-6 !p-0" />
                )}
              </TableBottombar>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

type DocumentCellProps = {
  fileName: string;
};

const DocumentCell = ({ fileName }: DocumentCellProps) => {
  const extension = fileName.includes('.')
    ? (fileName.split('.').pop() as keyof typeof fileTypesImg)
    : undefined;

  const title = useMemo(
    () => fileName.replace(`.${extension}`, ''),
    [fileName, extension],
  );

  return (
    <div className="flex items-center gap-3">
      <div className="rounded-sm border border-neutral-250 bg-neutral-100 p-2">
        {extension ? (
          <img
            className="h-5 w-5"
            src={fileTypesImg[extension]}
            alt={fileName}
          />
        ) : (
          <DocumentTextIcon className="h-6 w-6 text-neutral-600" />
        )}
      </div>
      <div className="flex items-center">
        <p className="text-[13px]">{extension ? title : fileName}</p>
      </div>
    </div>
  );
};
