import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';
import { zfd } from 'zod-form-data';

import type { Feature } from '~/models';

const schema = z.object({
  plan: zfd.text(z.string().optional()),
  widgets: zfd.numeric().default(0),
  knowledge_bases: zfd.numeric().default(0),
  sources: zfd.numeric().default(0),
  storage_gbs: zfd.numeric().default(0),
  storage_pages: zfd.numeric().default(0),
  priceOverride: zfd.numeric(),
  comment: zfd.text(z.string().optional()),
  popular_pages: zfd.checkbox(),
  trending_questions: zfd.checkbox(),
  knowledge_gaps: zfd.checkbox(),
  desktop_app: zfd.checkbox(),
  instant_answer_source: zfd.checkbox(),
  chat_integration: zfd.checkbox(),
  customer_service_extension: zfd.checkbox(),
  keyword_boosting: zfd.checkbox(),
  gtp3_summary: zfd.checkbox(),
  speech_recognition: zfd.checkbox(),
  public_api: zfd.checkbox(),
  public_api_bi: zfd.checkbox(),
  change_plan: zfd.checkbox(),
  daily_sync: zfd.checkbox(),
  raffle_chat: zfd.checkbox(),
  raffle_search: zfd.checkbox(),
  private_data: zfd.checkbox(),
  autocomplete: zfd.checkbox(),
  credits: zfd.numeric().optional(),
  consumption_based: zfd.checkbox(),
  recipients: zfd.repeatable(z.array(z.string())).optional(),
  monthly_credits: zfd.numeric().optional(),
});

export type SubscriptionValidatorData = z.infer<typeof schema>;
export type SubscriptionSchemaNames = keyof SubscriptionValidatorData;
export const subscriptionValidator = withZod(schema);

export function parseAddons(data: SubscriptionValidatorData) {
  const { priceOverride, comment, plan, change_plan, credits, ...rest } = data;
  const addons = Object.entries(rest).map(([name, quantity]) => ({
    name: name as Feature['name'],
    quantity: Number(quantity),
  }));

  return {
    addons,
    priceOverride,
    comment,
    plan,
    change_plan,
    credits,
  };
}
