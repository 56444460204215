import { tailwindConfig } from '@raffle-ai/design-system';
import { NavLink } from '@remix-run/react';
import { useMemo } from 'react';

import { Popup } from '~/components/Popup';
import { useSidebarState, useUser } from '~/providers';
import { cn, hasRolePermission } from '~/utils';

import type { Role } from '~/models';
import type { HeroIcon } from '~/types';

type SidebarButtonProps = {
  Icon: HeroIcon;
  className?: string;
  hide?: boolean;
  highlighted?: boolean;
  label: string;
  noLines?: boolean;
  onClick: () => void;
  roles?: Role[];
  to: string;
};

export const SidebarButton = ({
  Icon,
  label,
  to,
  className,
  roles = [],
  onClick,
  hide = false,
  noLines = false,
  highlighted = false,
}: SidebarButtonProps) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  const isAllowed = useMemo(
    () => hasRolePermission({ roles, userRoles: user.roles }),
    [roles, user.roles],
  );

  const ButtonComponent = useMemo(
    () => (
      <NavLink
        to={to}
        onClick={isAllowed ? onClick : (e) => e.preventDefault()}
        className={({ isActive, isPending }) =>
          cn(
            'duration-[250ms] group flex w-full cursor-default items-center gap-2 overflow-hidden rounded-sm bg-right px-1.5 py-1 transition-all ease-out',
            {
              'text-neutral-350 hover:bg-primary-550': !isActive && isAllowed,
              'gap-2': highlighted,
              'rounded-sm !bg-[length:200%_100%] !bg-left text-raffle-blue-dark':
                isActive,
              activeBackground: isActive,
              'cursor-not-allowed border-none text-grey': !isAllowed,
              'pointer-events-none border-grey-dark/40': isPending,
              'rounded-md p-3': minimise,
            },
            className,
          )
        }
      >
        <Icon
          className={cn(
            'z-10 h-4 w-4 text-neutral-400 transition-all group-hover:text-neutral-0',
            {
              'h-4 w-4': minimise,
              'h-[18px] w-[18px] rounded-sm bg-primary-500 p-0.5 !text-accent-blue-100':
                highlighted,
            },
          )}
        />

        {!minimise && (
          <>
            <p className="z-10 text-[13px] leading-normal !text-inherit">
              {label}
            </p>
          </>
        )}
      </NavLink>
    ),
    [to, isAllowed, onClick, Icon, minimise, label, className, highlighted],
  );

  if (!isAllowed && hide) {
    return null;
  }

  return (
    <div
      className={cn('flex', {
        relative: !noLines && !minimise,
        'w-full': !minimise,
        'w-min': minimise,
      })}
    >
      <Popup
        on="hover"
        disabled={!minimise}
        position="right center"
        trigger={ButtonComponent}
        fallback={ButtonComponent}
        arrowStyle={{ color: tailwindConfig.theme.colors.primary[450] }}
        offsetX={5}
        contentStyle={{
          boxShadow:
            'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(2, 76, 100, 0.1) 0px 0px 10px 0px',
        }}
      >
        <div className="rounded-md bg-dark-accent p-3">
          <p className="text-sm leading-normal !text-grey-light">{label}</p>
        </div>
      </Popup>
    </div>
  );
};
