import {
  ChevronRightIcon,
  DocumentTextIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { FlagIcon } from '@heroicons/react/24/solid';

import { Popup, PopupMenu, PopupMenuItem } from '~/components/Popup';
import { cn } from '~/utils';

import { SidebarCardButton } from './SidebarCardButton';

type LinksButtonType = {
  minimise: boolean;
};
export const SidebarLinks = ({ minimise }: LinksButtonType) => {
  const triggerButton = (
    <SidebarCardButton className="group cursor-default">
      <QuestionMarkCircleIcon
        className={cn('hidden h-4 w-4 text-white-off', {
          block: minimise,
        })}
      />
      {!minimise && (
        <>
          <div className="flex w-20 flex-col text-left">
            <p className="text-[13px] font-medium leading-normal text-neutral-350 transition-all group-hover:!text-neutral-250">
              Help
            </p>
          </div>

          <ChevronRightIcon className="h-3 text-neutral-350 transition-all group-hover:!text-neutral-250" />
        </>
      )}
    </SidebarCardButton>
  );

  return (
    <Popup
      fallback={triggerButton}
      trigger={triggerButton}
      position="right bottom"
      arrow={false}
    >
      <PopupMenu
        origin="bottom-left"
        className="shadow-m ml-2 flex flex-col gap-1 rounded-md border border-primary-500 bg-primary-550 p-1"
      >
        <PopupMenuItem
          Icon={FlagIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a
            href="https://status.raffle.ai/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-[13px] leading-normal !text-inherit">
              Status Page
            </p>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={DocumentTextIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a
            href="https://raffle.ai/docs/home"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-[13px] leading-normal !text-inherit">
              Documentation
            </p>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={PhoneIcon}
          className="!border-none !bg-primary-550 !text-neutral-350 hover:!bg-primary-500 hover:!text-neutral-250"
        >
          <a href="mailto:support@raffle.ai" target="_blank" rel="noreferrer">
            <p className="text-[13px] leading-normal !text-inherit">
              Contact Us
            </p>
          </a>
        </PopupMenuItem>
      </PopupMenu>
    </Popup>
  );
};
