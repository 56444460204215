import {
  EllipsisVerticalIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { User2Icon } from 'lucide-react';
import { forwardRef } from 'react';

import { useSidebarState, useUser } from '~/providers';
import { cn, parseRole, rolesDict } from '~/utils';

import { SidebarCardButton } from './SidebarCardButton';

export const UserButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  return (
    <SidebarCardButton
      className={cn('group !p-0 !pr-4 hover:bg-transparent', {
        'p-4 hover:!bg-primary-500': minimise,
      })}
      ref={ref}
      {...props}
    >
      <UserCircleIcon
        className={cn('hidden h-4 w-4 text-white-off', {
          block: minimise,
        })}
      />

      {!minimise && (
        <>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-md border border-primary-500 bg-primary-550 p-4 transition-all group-hover:border-primary-450 group-hover:bg-primary-500">
              <User2Icon className="h-3 w-3 text-accent-blue-100" />
            </div>
            <div className="flex w-20 flex-col text-left">
              <p className="truncate text-[13px] font-medium leading-normal !text-neutral-350 transition-all group-hover:!text-neutral-200">
                {user.full_name.split(' ')[0]}
              </p>

              <p className="text-xs leading-normal !text-neutral-400 transition-all group-hover:!text-neutral-350">
                {rolesDict[parseRole(user.roles)]}
              </p>
            </div>
          </div>

          <EllipsisVerticalIcon className="h-4 text-neutral-350 transition-all group-hover:!text-neutral-200" />
        </>
      )}
    </SidebarCardButton>
  );
});

UserButton.displayName = 'UserButton';
