import { PlusIcon } from '@heroicons/react/20/solid';
import { ListBulletIcon } from '@heroicons/react/24/solid';
import { Link, useFetcher, useLocation, useNavigation } from '@remix-run/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';

import { usePreviousValue } from '~/hooks';
import { useUser } from '~/providers';

import Button from '../Button';
import DynamicTooltip from '../DynamicTooltip';
import { Input } from '../Form';
import LoadingSpinner from '../LoadingSpinner';
import AccountSwitchForm from './AccountSwitchForm';
import { AnimatedTabs } from './AnimatedTabs';

import type { VirtualItem } from '@tanstack/react-virtual';
import type { Account } from '~/models';
import type { ViewData } from '~/routes/_app.backstage._index/View';

const AccountSwitcher = ({ close }: { close: () => void }) => {
  const { pathname } = useLocation();
  const [tabFilterId, setTabFilterId] = useState('all');

  const fetcher = useFetcher<ViewData>();

  const { account: currentAccount } = useUser();
  const previousAccountId = usePreviousValue(currentAccount.id);

  const ref = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!fetcher.data && fetcher.state === 'idle') {
      fetcher.load('/action/accounts');
    }
  }, [fetcher]);

  useEffect(() => {
    if (previousAccountId && previousAccountId !== currentAccount.id) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount.id]);

  const accounts = useMemo(() => {
    const lowerCaseFilter = filter.toLowerCase();

    return (
      fetcher.data?.accounts?.filter((acc) => {
        if (acc.id === currentAccount.id) return false;
        if (tabFilterId === 'onboarding' && acc.is_onboarding === false)
          return false;

        if (filter.length > 0) {
          return (
            acc.title.toLowerCase().includes(lowerCaseFilter) ||
            acc.id.toString().includes(lowerCaseFilter)
          );
        }
        return true;
      }) ?? []
    );
  }, [fetcher.data, filter, currentAccount, tabFilterId]);

  const redirectTo = useMemo(() => {
    const paths = pathname.split('/');

    if (paths.includes('backstage')) {
      return `/${paths[1]}/${paths[2] ?? ''}`;
    }

    if (paths.includes('insights')) {
      return `/insights/dashboard`;
    }

    return `/${paths[1]}`;
  }, [pathname]);

  const virtualizedAccounts = useVirtualizer({
    getScrollElement: () => ref.current,
    count: accounts.length,
    estimateSize: () => 50,
  });

  return (
    <div className="flex flex-col rounded-lg border border-neutral-250">
      <div className="flex h-[40rem] w-[32rem] flex-col justify-start p-4 pb-0">
        <p className="p-2 text-center text-base font-semibold leading-normal text-neutral-600">
          Account Selector
        </p>

        <div className="my-4">
          <Input
            id="search-accounts-list"
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onClearSearch={() => setFilter('')}
            placeholder="Search accounts by name or id"
          />
        </div>

        <div className="flex border-y border-neutral-250 bg-neutral-50 py-3">
          <AnimatedTabs
            tabs={typeFilters}
            onSelect={setTabFilterId}
            activeTabId={tabFilterId}
          />
        </div>

        <div className="overflow-auto" ref={ref}>
          <AccountItem
            account={currentAccount}
            isCurrent
            redirectTo={redirectTo}
          />
          <div
            style={{
              height:
                fetcher.state === 'loading'
                  ? '100%'
                  : virtualizedAccounts.getTotalSize() || '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            {fetcher.state === 'loading' ? (
              <LoadingSpinner className="h-full" />
            ) : (
              <>
                {virtualizedAccounts
                  .getVirtualItems()
                  ?.map((virtualAccount) => (
                    <AccountItem
                      key={accounts[virtualAccount.index].id}
                      account={accounts[virtualAccount.index]}
                      virtualAccount={virtualAccount}
                      redirectTo={redirectTo}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex w-full gap-4 rounded-b-lg border-t border-t-neutral-250 bg-neutral-100 p-4">
        <Link to="/backstage" onClick={() => close()} className="flex-1">
          <Button
            className="w-full"
            variant="primary"
            iconClassName="mr-auto"
            Icon={ListBulletIcon}
          >
            <div className="mr-auto">See full list</div>
          </Button>
        </Link>

        <Link to="/backstage/new" onClick={() => close()} className="flex-1">
          <Button className="w-full" iconClassName="mr-auto" Icon={PlusIcon}>
            <div className="mr-auto">Create new account</div>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AccountSwitcher;

const typeFilters = [
  { id: 'all', label: 'All' },
  { id: 'onboarding', label: 'Onboarding' },
];

const AccountItem = ({
  account,
  isCurrent = false,
  virtualAccount,
  redirectTo,
}: {
  account: Account;
  isCurrent?: boolean;
  redirectTo: string;
  virtualAccount?: VirtualItem;
}) => {
  const navigation = useNavigation();

  const disabled = isCurrent || navigation.state !== 'idle';

  const isOnboarding =
    account.plan_name === 'free' ||
    account.plan_name === 'demo' ||
    account.plan_name === 'trial';

  const isPro = account.plan_name === 'pro';

  return (
    <div
      className={clsx(
        'flex flex-row justify-between border-b border-grey-light p-2 pl-4',
        { 'rounded-lg border-b-0 bg-neutral-150': isCurrent },
      )}
      {...(virtualAccount && {
        style: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: `${virtualAccount.size}px`,
          transform: `translateY(${virtualAccount.start}px)`,
        },
      })}
    >
      <div className="flex max-w-[75%] flex-row items-center gap-2">
        <DynamicTooltip
          content={
            <p className="text-sm leading-normal text-neutral-450">
              {account.title}
            </p>
          }
        >
          <p className="truncate text-sm leading-normal text-neutral-450">
            {account.title}
          </p>
        </DynamicTooltip>
        <p className="rounded-lg border border-grey-light bg-white-off px-2 py-1 text-sm text-neutral-450">
          {account.id}
        </p>

        {(isOnboarding || isPro) && (
          <p
            className={clsx(
              'rounded-lg bg-dark-accent px-2 py-1 text-sm text-white-off',
              {
                '!bg-accent-purple-400': isOnboarding,
              },
            )}
          >
            {account.plan_name}
          </p>
        )}
      </div>

      <AccountSwitchForm
        id={`switch-account-${account.id}`}
        action="/backstage?index"
        defaultValues={{
          account_id: account.id,
          account_title: account.title,
          redirect_to: redirectTo,
        }}
      >
        <Button type="submit" disabled={disabled} className="h-full">
          Switch
        </Button>
      </AccountSwitchForm>
    </div>
  );
};
